<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authFackMethods } from "@/state/helpers";
// eslint-disable-next-line no-unused-vars
import moment from 'moment'
// import { tableData } from "./sample";
// import { mapState } from 'vuex';

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "User Management List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      // tableData: tableData,
      loading: false,
      user_list: [],
      title: "User Management",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "User List",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: true,
      fields: [
        { index: 'No' },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "primary_contact",
          sortable: false,
        },
        {
          key: "role",
          sortable: true,
        },
        {
          key: "created_at",
          sortable: true,
        },
        {
          key: "updated_at",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: 'operation'
        }
      ],

      password:{
        new_pass: '',
        new_pass2: '',
        user_uuid: '',
      }
    };
  },
  computed: {
    rows() {
      return this.user_list.length || 0
    },
    getRole(){
      let user = JSON.parse(localStorage.getItem('user'));
      return user.role
    },
    compute_user(){
      return this.$store.state.authfack.user || {
        permissions: ''
      }
    },
    lock_viewBtn(){
      if(this.compute_user.permissions.includes("*") || this.compute_user.permissions.includes('014') ){
        // console.log('has permissions allowed')
        return false
      }
      else{
        // console.log('no permissions')
        return true
      }
    },
    lock_editBtn(){
      if(this.compute_user.permissions.includes("*") || this.compute_user.permissions.includes('012') ){
        // console.log('has permissions allowed')
        return false
      }
      else{
        // console.log('no permissions')
        return true
      }
    },
    lock_createBtn(){
      if(this.compute_user.permissions.includes("*") || this.compute_user.permissions.includes('011') ){
        // console.log('has permissions allowed')
        return false
      }
      else{
        // console.log('no permissions')
        return true
      }
    },
    lock_setPassword(){
      if(this.compute_user.permissions.includes("*") || this.compute_user.permissions.includes('016') ){
        // console.log('has permissions allowed')
        return false
      }
      else{
        // console.log('no permissions')
        return true
      }
    }
  },
  created(){
    if(this.compute_user.permissions.includes("*") || this.compute_user.permissions.includes("015")){
      this.load_list()
    }
  },
  methods: {
    ...authFackMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    add(){
      this.$router.push("/user/create");
    },
    view(uuid){
      this.$router.push(`/user/${uuid}`);
    },
    resetPassword(uuid){
      this.password.new_pass = ''
      this.password.new_pass2 = ''
      this.password.user_uuid = uuid

      this.$bvModal.show('modal-reset-password')
    },
    reset(){
      let self = this
      if(this.password.new_pass !== this.password.new_pass2){
        alert('Password mismatch')
      }
      else{
        const baseURI = 'users/set_password/'+this.password.user_uuid
        let formData = new FormData();
        formData.append('new_password', this.password.new_pass);

        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            self.$bvModal.hide('modal-reset-password')
            self.$notify({
              group: 'app',
              title: 'User Password Reset',
              text: 'Successfully reset user password',
              type: 'success'
            });
          }
        })
      }
    },
    // load_list(){
    //   this.loading = true
    //   const baseURI = 'users/list'
    //   let formData = new FormData();

    //   let role = '' // role set '' for SUPERADMIN 
    //   if(this.getRole == 'ADMIN'){
    //     role = 'ADMIN'
    //   }
    //   else if(this.getRole == 'COLLECTOR'){
    //     role = 'COLLECTOR'
    //   }

    //   formData.append('val', '');
    //   formData.append('role', role);
    //   formData.append('status', 'ACTIVE,INACTIVE,DELETED');

    //   this.$http.post(baseURI,formData)
    //   .then((result) => {
    //     if(result.data.status == 'success'){
    //       this.user_list = result.data.data
    //       this.loading = false
    //     }
    //   })
    //   .catch((err) => {
    //     if(err == 'session_timeout'){
    //       this.$notify({
    //         group: 'auth',
    //         title: 'User Authentication',
    //         text: 'Your session has expired, please login again',
    //         type: 'warn'
    //       });
    //       this.logout();
    //       this.$router.push({
    //         path: "/login",
    //       });
    //     }
    //   })
    // },
    load_list: async function(){

      let self = this
      this.loading = true
      const baseURI = 'users/list'
      let formData = new FormData();

      let role = '' // role set '' for SUPERADMIN 
      if(this.getRole == 'ADMIN'){
        role = 'ADMIN'
      }
      else if(this.getRole == 'COLLECTOR'){
        role = 'COLLECTOR'
      }

      formData.append('val', '');
      formData.append('role', role);
      formData.append('status', 'ACTIVE,INACTIVE,DELETED');

      try{
       let response =  await self.$http.post(baseURI,formData)
        this.loading = false
        this.user_list = response.data.data;
      }catch(error) {
        if(error == 'session_timeout'){
          this.logout();
          this.$router.push({
            path: "/login",
          });
        }
      }
      
      // return self.user_list
    },
    toggle_status(user_info){
      let self = this

      var r = confirm(`Set this to ${user_info.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' }?`);
      if (r == true) {
        self.loading = true
        // https://www.maxwell777.com/dev/api/v1/
        const baseURI = 'users/'+user_info.uuid
        let formData = new FormData()
        formData.append('_method', 'PUT' )
        formData.append('status', `${user_info.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' }` )
        self.$http.post(baseURI, formData)
        .then((result) => {
          // console.log(result)
          if(result.data.status == 'success'){
            self.loading = false
            self.$notify({
              group: 'app',
              title: `User is now ${user_info.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`,
              type: 'success'
            });
            self.load_list()
          }
        })
        .catch((err) => {
          if(err == 'session_timeout'){
            this.logout();
            this.$router.push({
              path: "/login",
            });
          }
        })
      }
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay :show="loading" rounded="sm">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">User List
                <div style="float:right"><b-button size="sm" variant="primary" :disabled="lock_createBtn" v-show="!lock_createBtn" @click="add">Add User<i class="uil uil-plus ms-2"></i></b-button></div>
              </h4>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="user_list"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(created_at)="data">
                  {{ moment(data.item.created_at).format('YYYY-MM-DD HH:mm') }}
                </template>

                <template #cell(updated_at)="data">
                  {{ moment(data.item.updated_at).format('YYYY-MM-DD HH:mm') }}
                </template>

                <!-- A virtual composite column -->
                <template #cell(operation)="data">
                  <ul class="list-inline mb-0" v-show="data.item.status !== 'DELETED' ">
                    <li class="list-inline-item">
                      <a
                        v-show="!lock_setPassword"
                        href="javascript:void(0);"
                        class="px-2 text-warning"
                        v-b-tooltip.hover
                        title="Reset Password"
                        @click="resetPassword(data.item.uuid)"
                      >
                      <i class="fas fa-key font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        v-show="!lock_viewBtn"
                        href="javascript:void(0);"
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        @click="view(data.item.uuid)"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        v-show="!lock_editBtn && compute_user.id !== data.item.uuid"
                        href="javascript:void(0);"
                        :class="[ data.item.status == 'ACTIVE' ? 'text-danger' : 'text-success', 'px-2']"
                        v-b-tooltip.hover
                        title="Update status"
                        @click="toggle_status(data.item)"
                      >
                        <i 
                        :class="[ data.item.status == 'ACTIVE' ? 'uil-lock-alt' : 'uil-lock-open-alt', 'uil', 'font-size-18']"
                        ></i>
                      </a>
                    </li>
                  </ul>
                  <span v-show="data.item.status == 'DELETED' " class="text-muted"> No action </span>
                </template>

                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-start"
                  >
                    <p><b>Total : {{rows}}</b></p>
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        total-rows="10"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-modal
            id="modal-reset-password"
            centered
            title="Reset Password"
            title-class="font-18"
            hide-footer
          >
            <div class="row">
              <div class="col-lg-6">
                <b-form-group
                  label="New Password / 新密码"
                  label-for="form-new-pass"
                  class="mb-3"
                >
                  <b-form-input
                    id="form-new-pass"
                    type="text"
                    v-model="password.new_pass"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group
                  label="Reenter Password / 确认密码"
                  label-for="form-new-pass2"
                  class="mb-3"
                >
                  <b-form-input
                    id="form-new-pass2"
                    type="text"
                    v-model="password.new_pass2"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="modal-footer">
              <button href="javascript:void(0)" class="btn btn-success ms-1" @click="reset">
                <i class="uil uil-file-alt me-1"></i> Save
              </button>
            </div>
          </b-modal>

        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
